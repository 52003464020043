@import "~@/styles/variables.scss";
.lodash-container {
  text-align: left;

  ::v-deep {
    .lodash-content {
      min-height: 150px;
    }
  }
}
