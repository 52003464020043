@import "~@/styles/variables.scss";
.more-container {
  ::v-deep {
    .el-card__body {
      > div {
        min-height: 220px;
        padding: 20px;

        > ul {
          > li {
            line-height: 30px;
          }
        }

        > img {
          display: block;
          margin: 40px auto;
          border: 1px solid #dedede;
        }
      }
    }
  }
}
