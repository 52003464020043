@import "~@/styles/variables.scss";
.colorful-icon-container {
  ::v-deep {
    .el-card__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */

      svg:not(:root),
      .svg-external-icon {
        font-size: 16px;
        font-weight: bold;
        color: $base-color-gray;
        text-align: center;
        vertical-align: middle;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }

  .icon-text {
    height: 30px;
    margin-top: -15px;
    overflow: hidden;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
