@import "~@/styles/variables.scss";
.loading-container {
  ::v-deep {
    .el-button {
      margin-top: 10px;
      margin-right: 10px;
      margin-left: 0;
    }

    .el-button + .el-button {
      margin-right: 10px;
      margin-left: 0;
    }
  }
}
