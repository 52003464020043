@import "~@/styles/variables.scss";
.small-components-container {
  ::v-deep {
    .el-card__body {
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      height: 430px;
    }
  }
}
