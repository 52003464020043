@import "~@/styles/variables.scss";
.element-container {
  ::v-deep {
    .el-dialog__wrapper {
      position: fixed;
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
    }

    .el-tag,
    .el-button,
    .el-link {
      margin: 5px;
    }

    .el-progress {
      margin: 20px;
    }
  }

  .element-iframe {
    position: absolute;
    top: 55px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 89vh;
  }
}
