@import "~@/styles/variables.scss";
.vab-image {
  &__outter {
    position: relative;
    width: 100%;
    height: 100%;

    ::v-deep {
      img {
        border-radius: $base-border-radius;
      }
    }

    &__small {
      position: absolute;
      top: 0;
      right: 0;
      width: 80px;
      height: 100px;
      border-bottom: 1px solid $base-color-white;
      border-left: 1px solid $base-color-white;
      border-radius: $base-border-radius;
    }

    &__percent {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      min-width: 50px;
      height: 25px;
      line-height: 25px;
      color: $base-color-white;
      text-align: center;
      background-color: $base-color-red;
      border-radius: $base-border-radius;
    }
  }
}
