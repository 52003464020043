@import "~@/styles/variables.scss";
/* 移除删除文件时的动画 */
.pdf {
  position: relative;
}
.pdf_meng {
  position: absolute;
  top: 200px;
  left: 200px;
  width: 150px;
  height: 30px;
  z-index: 100;
  background-color: #ccc;
}

::v-deep .el-upload-list__item {
  transition: none !important;
}
::v-deep .el-upload-list__item.is-ready {
  //因为此饿了么组件图片上传的时候会产生一个类,将其隐藏就可以解决上传图片完成后闪动的问题
  display: none;
}
.show {
  display: flex;
  flex-wrap: wrap;
}
.navBer::v-deep .el-upload-list--picture-card {
  margin: 0;
  display: block;
  /* vertical-align: top; */
  display: contents;
}

.navBer::v-deep .el-upload--picture-card {
  width: 200px;
  height: 124px;
  margin-top: 15px;
  border-radius: 8px;
}
.navBer::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 124px;
  border-radius: 8px;
  margin: 15px 8px 0px 0px;
  border: 1px solid #c0ccda;
}

.up_class {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 200px;
  height: 134px;
  line-height: 20px;
}
.hide {
  position: relative;
  float: left;
  ::v-deep {
    .el-upload--picture-card {
      display: none;
    }

    .el-upload-list__item {
      border: 0;
      border-radius: 0;
      margin: 0 15px 0 0;
    }
  }
}
